<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('account.couriers.title') }}</span>
                <span style="float: right">
                    <el-button @click="dialogInsert = true" type="primary" icon="el-icon-plus" size="mini">
                        {{ $t('account.couriers.button-insert') }}
                    </el-button>
                </span>
            </div>

            <Box v-if="!courierMin1CC" type="warning" :active="true" style="margin-top: 0">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li>{{ $t('account.couriers.alert_card_cash') }}</li>
                    </ul>
                </div>
            </Box>

            <Box v-if="useautoshipping == 1 && couriers.length > 1" type="info" :active="true" style="margin-top: 0">
                <div slot="box-body">
                    {{ $t('account.couriers.chooseshippingpolicy') }}:

                    <div style="margin-top: 15px">
                        <el-radio v-model="shippingPolicy" :label="1" @change="handleSelectionChange" border>{{
                            $t('account.couriers.chooseshippingpolicy_min')
                        }}</el-radio>
                        <el-radio v-model="shippingPolicy" :label="2" @change="handleSelectionChange" border>{{
                            $t('account.couriers.chooseshippingpolicy_max')
                        }}</el-radio>
                        <el-radio v-model="shippingPolicy" :label="3" @change="handleSelectionChange" border>{{
                            $t('account.couriers.chooseshippingpolicy_default')
                        }}</el-radio>
                    </div>
                </div>
            </Box>

            <el-table
                :data="couriers"
                :empty-text="$t('account.couriers.no-accounts')"
                style="width: 100%"
                size="small"
                border
            >
                <el-table-column prop="id" :label="$t('account.couriers.labels.id')" width="100px"></el-table-column>
                <el-table-column prop="courier_name" :label="$t('account.couriers.labels.courier')"></el-table-column>
                <el-table-column prop="name" :label="$t('account.couriers.labels.name')"></el-table-column>
                <el-table-column :label="$t('account.couriers.labels.status')">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 1" type="success">{{
                            $t('account.couriers.status-active')
                        }}</el-tag>
                        <el-tag v-else type="danger">{{ $t('account.couriers.status-inactive') }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('account.couriers.labels.favorite')">
                    <template slot-scope="scope">
                        <el-tooltip
                            v-if="scope.row.default_orders == 1"
                            effect="light"
                            :content="$t('account.couriers.default-orders')"
                            placement="top"
                        >
                            <el-tag type="warning" size="medium"><i class="el-icon-truck"></i></el-tag>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column width="290px">
                    <template slot-scope="scope">
                        <el-tooltip effect="light" :content="$t('account.couriers.edit-title')" placement="top">
                            <el-button
                                @click="editAccount(scope.row.id)"
                                type="primary"
                                size="mini"
                                icon="el-icon-edit"
                                plain
                            ></el-button>
                        </el-tooltip>

                        <el-tooltip
                            v-if="!scope.row.default_orders"
                            effect="light"
                            :content="$t('account.couriers.default-orders')"
                            placement="top"
                        >
                            <el-button
                                @click="setFavorite(scope.row.id, 'orders')"
                                type="warning"
                                size="mini"
                                icon="el-icon-truck"
                                plain
                            ></el-button>
                        </el-tooltip>

                        <template v-if="courierHasLockers(scope.row)">
                            <el-tooltip
                                effect="light"
                                :content="$t('account.couriers.tooltip-lockers-da')"
                                placement="top"
                            >
                                <el-button type="success" size="mini" icon="el-icon-s-grid" plain></el-button>
                            </el-tooltip>
                        </template>
                        <template v-else>
                            <el-tooltip
                                effect="light"
                                :content="$t('account.couriers.tooltip-lockers-nu')"
                                placement="top"
                            >
                                <el-button type="info" size="mini" icon="el-icon-s-grid" plain></el-button>
                            </el-tooltip>
                        </template>

                        <template
                            v-if="scope.row.courier_uid === 'sameday' || scope.row.courier_uid === 'urgentcargus'"
                        >
                            <el-tooltip
                                effect="light"
                                :content="$t('account.couriers.update-pickup-points')"
                                placement="top"
                            >
                                <el-button
                                    @click="updatePickupPoints(scope.row.id)"
                                    type="primary"
                                    size="mini"
                                    icon="el-icon-refresh"
                                    plain
                                ></el-button>
                            </el-tooltip>
                        </template>

                        <el-tooltip effect="light" :content="$t('account.couriers.tooltip-remove')" placement="top">
                            <el-button
                                @click="deleteAccount(scope.row.id)"
                                type="danger"
                                :disabled="scope?.row?.default_orders == 1 || scope?.row?.default_returns == 1"
                                size="mini"
                                icon="el-icon-delete"
                                plain
                            ></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog
            :visible.sync="dialogInsert"
            :title="formId == 0 ? $t('account.couriers.insert-title') : $t('account.couriers.edit-title')"
            @closed="resetForm"
            class="dialog-lg"
        >
            <el-steps
                v-if="formId == 0"
                :active="activeStep"
                align-center
                finish-status="success"
                class="courier-steps"
            >
                <el-step :title="$t('account.couriers.insert-step-1')"></el-step>
                <el-step :title="$t('account.couriers.insert-step-2')"></el-step>
            </el-steps>

            <template v-if="activeStep == 0">
                <el-form :model="form" :rules="formRules.step_1" ref="form" label-position="top">
                    <el-form-item prop="courier_id">
                        <el-row>
                            <el-col v-for="(courier, index) in couriers_list" :xs="24" :md="8" :key="index">
                                <div
                                    :class="'courier ' + (formCourierId == courier.id ? 'active' : '')"
                                    @click="setCourier(courier.id)"
                                >
                                    <img
                                        :src="require('@/assets/images/couriers/' + courier.uid + '.png')"
                                        class="courier-image"
                                    />
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
            </template>

            <template v-if="activeStep == 1">
                <el-form :model="form" :rules="formRules.step_2" ref="form" label-position="top">
                    <el-row :gutter="20">
                        <el-col :xs="24" :md="12">
                            <div class="box-title">{{ $t('account.couriers.login-data') }}</div>

                            <template v-if="courier.uid === 'sameday'">
                                <el-form-item
                                    :label="$t('account.couriers.labels.seller_id')"
                                    :rules="[
                                        {
                                            required: true,
                                            message: $t('account.couriers.errors.seller_id'),
                                            trigger: ['change', 'blur'],
                                        },
                                    ]"
                                    prop="client_id"
                                >
                                    <el-input v-model="formClientId"></el-input>
                                </el-form-item>
                            </template>

                            <template
                                v-if="
                                    courier.uid === 'fancourier' ||
                                    courier.uid === 'gls' ||
                                    courier.uid.includes('mygls')
                                "
                            >
                                <el-form-item
                                    :label="$t('account.couriers.labels.client_id')"
                                    :rules="[
                                        {
                                            required: true,
                                            message: $t('account.couriers.errors.client_id'),
                                            trigger: ['change', 'blur'],
                                        },
                                    ]"
                                    prop="client_id"
                                >
                                    <el-input v-model="formClientId"></el-input>
                                </el-form-item>
                            </template>

                            <template v-if="courier.uid === 'clickcolet' || courier.uid === 'bookurier'">
                                <el-form-item
                                    :label="$t('account.couriers.labels.pickup_id')"
                                    :rules="[
                                        {
                                            required: true,
                                            message: $t('account.couriers.errors.pickup_id'),
                                            trigger: ['change', 'blur'],
                                        },
                                    ]"
                                    prop="client_id"
                                >
                                    <el-input v-model="formClientId"></el-input>
                                </el-form-item>
                            </template>

                            <template
                                v-if="
                                    courier.uid === 'fancourier' ||
                                    courier.uid === 'coleteonline' ||
                                    courier.uid === 'urgentcargus' ||
                                    courier.uid === 'dpd' ||
                                    courier.uid === 'clickcolet' ||
                                    courier.uid === 'woot' ||
                                    courier.uid === 'fastius' ||
                                    courier.uid === 'gls' ||
                                    courier.uid === 'bookurier' ||
                                    courier.uid.includes('mygls')
                                "
                            >
                                <el-form-item
                                    :label="$t('account.couriers.labels.username')"
                                    :rules="[
                                        {
                                            required: true,
                                            message: $t('account.couriers.errors.username'),
                                            trigger: ['change', 'blur'],
                                        },
                                    ]"
                                    prop="username"
                                >
                                    <el-input v-model="formUsername"></el-input>
                                </el-form-item>
                                <el-form-item
                                    :label="$t('account.couriers.labels.password')"
                                    :rules="[
                                        {
                                            required: true,
                                            message: $t('account.couriers.errors.password'),
                                            trigger: ['change', 'blur'],
                                        },
                                    ]"
                                    prop="password"
                                >
                                    <el-input v-model="formPassword" type="password"></el-input>
                                </el-form-item>
                            </template>

                            <template
                                v-if="
                                    courier.uid === 'nemoexpress' ||
                                    courier.uid === 'stackcurier' ||
                                    courier.uid === 'couriermanager' ||
                                    courier.uid === 'teamcourier'
                                "
                            >
                                <el-form-item
                                    :label="$t('account.couriers.labels.api_key')"
                                    :rules="[
                                        {
                                            required: true,
                                            message: $t('account.couriers.errors.api_key'),
                                            trigger: ['change', 'blur'],
                                        },
                                    ]"
                                    prop="password"
                                >
                                    <el-input v-model="formPassword"></el-input>
                                </el-form-item>
                            </template>

                            <template v-if="courier.uid === 'urgentcargus'">
                                <el-form-item
                                    :label="$t('account.couriers.labels.api_key')"
                                    :rules="[
                                        {
                                            required: true,
                                            message: $t('account.couriers.errors.api_key'),
                                            trigger: ['change', 'blur'],
                                        },
                                    ]"
                                    prop="password"
                                >
                                    <el-input v-model="formClientId"></el-input>
                                </el-form-item>
                            </template>
                            <template v-if="courier.uid === 'ecolet'">
                                <div class="ecolet-courier-container">
                                    <el-row type="flex" justify="center" align="center">
                                        <el-tag v-if="formClientId" type="success" size="large">{{
                                            $t('account.couriers.ecolet-connect-successful')
                                        }}</el-tag>
                                    </el-row>

                                    <el-row type="flex" justify="center" align="center">
                                        <el-button
                                            v-if="!formClientId || (formId && formPassword)"
                                            @click="openEcoletAuthorization"
                                            type="primary"
                                            icon="el-icon-connection"
                                            plain
                                            :size="formClientId ? 'mini' : 'medium'"
                                        >
                                            {{
                                                formId
                                                    ? $t('account.couriers.ecolet-reconnect')
                                                    : $t('account.couriers.ecolet-connect')
                                            }}
                                        </el-button>
                                    </el-row>
                                </div>
                            </template>
                        </el-col>
                        <el-col :xs="24" :md="12">
                            <div class="box-title">{{ $t('account.couriers.account-settings') }}</div>

                            <el-form-item :label="$t('account.couriers.labels.name')" prop="name">
                                <el-input v-model="formName" :disabled="formId > 0"></el-input>
                            </el-form-item>

                            <template v-if="courier.services && courier.services.length > 0">
                                <el-form-item :label="$t('account.couriers.labels.service')" prop="service">
                                    <el-select class="fullWidth" v-model="formService" filterable>
                                        <el-option
                                            v-for="(service, index) in courier.services"
                                            :value="service.value"
                                            :label="service.text"
                                            :key="index"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>

                            <el-form-item :label="$t('account.couriers.labels.awb_format')" prop="awb_format">
                                <el-select class="fullWidth" v-model="formAwbFormat" filterable>
                                    <el-option
                                        v-for="awb_format in courier.awb_formats"
                                        :value="awb_format"
                                        :label="awb_format"
                                        :key="awb_format"
                                    ></el-option>
                                </el-select>
                            </el-form-item>

                            <el-checkbox v-if="courier.epod" v-model="formEpod" label="ePOD"></el-checkbox>
                        </el-col>
                    </el-row>

                    <el-row v-if="courier.uid === 'sameday'">
                        <el-col :span="24">
                            <Box :type="'info'" :active="true" style="margin-top: 0">
                                <div slot="box-body">
                                    <ul class="rawUl">
                                        <li>
                                            <span style="font-weight: bold">ID-ul de vanzator</span> este un ID special
                                            pentru CEL Marketplace si se poate obtine contactand
                                            <span style="font-weight: bold">Sameday</span>
                                        </li>
                                    </ul>
                                </div>
                            </Box>
                        </el-col>
                    </el-row>

                    <el-row v-else-if="courier.uid === 'dpd'">
                        <el-col :span="24">
                            <Box :type="'info'" :active="true" style="margin-top: 0">
                                <div slot="box-body">
                                    <ul class="rawUl">
                                        <li>
                                            <span style="font-weight: bold">Numele de utilizator</span> si
                                            <span style="font-weight: bold">Parola</span> sunt speciale pentru API, nu
                                            cele ale contului <span style="font-weight: bold">MyDPD</span>, si se pot
                                            obtine contactand <span style="font-weight: bold">DPD</span>
                                        </li>
                                    </ul>
                                </div>
                            </Box>
                        </el-col>
                    </el-row>

                    <el-row v-else-if="courier.uid === 'ecolet'">
                        <el-col :span="24">
                            <Box :type="'info'" :active="true" style="margin-top: 0">
                                <div slot="box-body">
                                    <ul class="rawUl">
                                        <li>
                                            Conecteaza contul Ecolet la platforma CEL Marketplace apasand butonul
                                            <strong>{{ $t('account.couriers.ecolet-connect') }}</strong>
                                        </li>
                                    </ul>
                                </div>
                            </Box>
                        </el-col>
                    </el-row>

                    <el-row v-else>
                        <el-col :span="24">
                            <Box :type="'info'" :active="true" style="margin-top: 0">
                                <div slot="box-body">
                                    <ul class="rawUl">
                                        <li>
                                            <span style="font-weight: bold">Datele de autentificare</span> se pot obtine
                                            contactand <span style="font-weight: bold">{{ courier.name }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </Box>
                        </el-col>
                    </el-row>

                    <el-row :gutter="20">
                        <el-col>
                            <el-row :gutter="20">
                                <el-col>
                                    <div class="box-title">{{ $t('account.couriers.courier-settings-costuri') }}</div>

                                    <el-row
                                        v-if="
                                            courier.uid === 'sameday' &&
                                            courier.hasAutomatedCalc &&
                                            !formCalculareDupaContract
                                        "
                                    >
                                        <el-col :span="24">
                                            <Box :type="'info'" :active="true" style="margin-top: 0">
                                                <div slot="box-body">
                                                    <ul class="rawUl">
                                                        <li>
                                                            <span style="font-weight: bold">Atentie!</span>
                                                            Sameday nu furnizeaza o lista prin API a localitatilor cu
                                                            costuri suplimentare. Daca setati manual costul livrarilor,
                                                            nu se vor lua in considerare livrarile in localitatile cu
                                                            costuri extra, ceea ce poate duce la un
                                                            <span style="font-weight: bold">pret incorect</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Box>
                                        </el-col>
                                    </el-row>

                                    <el-col :xs="24" :md="12" v-if="courier.hasAutomatedCalc">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.calculateByContract') }}
                                                <el-tooltip
                                                    :content="
                                                        $t(
                                                            'account.couriers.tooltips-curieri-modal.formCalculareDupaContract'
                                                        )
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select
                                                v-model="formCalculareDupaContract"
                                                placeholder="Select"
                                                class="fullWidth"
                                            >
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.add_tva') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.formAddTva')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="formAddTva" placeholder="Select" class="fullWidth">
                                                <el-option :label="$t('general.no')" :value="1"></el-option>
                                                <el-option :label="$t('general.yes')" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12" v-if="!formCalculareDupaContract">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.baza') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formPretBaza')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formPretBaza"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" v-if="1 == 2 /*btemp*/">
                                <el-col>
                                    <div class="box-title">
                                        {{ $t('account.couriers.courier-settings-costuri-minime') }}
                                    </div>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.minimumPrice') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formMinimumPrice')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formMinimumPrice"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.minimumPrice') }}
                                                <el-tooltip
                                                    :content="
                                                        $t(
                                                            'account.couriers.tooltips-curieri-modal.formMinimumPriceBuc'
                                                        )
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formMinimumPriceBuc"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-col>
                                    <div class="box-title">
                                        {{ $t('account.couriers.courier-settings-livrare-gratuita') }}
                                    </div>

                                    <Box
                                        v-if="formLimita === 0 || formLimitaBuc === 0"
                                        type="warning"
                                        :active="true"
                                        style="margin-top: 0"
                                    >
                                        <div slot="box-body">
                                            <ul class="rawUl">
                                                <li>{{ $t('account.couriers.alert_free_transport') }}</li>
                                            </ul>
                                        </div>
                                    </Box>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.limita') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.formLimita')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formLimita"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.limitabuc') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formLimitaBuc')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formLimitaBuc"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" v-if="formUseautoshipping && courier.hasExtraKgPrice">
                                <el-col>
                                    <div class="box-title">
                                        {{ $t('account.couriers.courier-settings-costuri-kgextra') }}
                                    </div>

                                    <el-col :xs="12" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.calculateKg') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.calculateKg')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="formCalculateKg" placeholder="Select" class="fullWidth">
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col
                                        :xs="24"
                                        :md="12"
                                        v-if="formCalculateKg === 1 && !formCalculareDupaContract"
                                    >
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.kgincluse') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formKgIncluse')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formKgIncluse"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>

                                    <el-col
                                        :xs="24"
                                        :md="12"
                                        v-if="formCalculateKg === 1 && !formCalculareDupaContract"
                                    >
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.extraKg') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.formExtraKg')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formExtraKg"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row
                                :gutter="20"
                                v-if="formUseautoshipping && courier.hasExtKm && !formCalculareDupaContract"
                            >
                                <el-col>
                                    <div class="box-title">
                                        {{ $t('account.couriers.courier-settings-costuri-kmextra') }}
                                    </div>

                                    <el-col :xs="12" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.calculateKm') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.calculateKm')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="formCalculateKm" placeholder="Select" class="fullWidth">
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12" v-if="formCalculateKm">
                                        <el-form-item>
                                            <label class="el-form-item__label" v-if="courier.hasExtFixedPrice">
                                                {{ $t('account.couriers.labels.extraKm_fix') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.formExtraKm')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <label class="el-form-item__label" v-if="courier.hasExtVariablePrice">
                                                {{ $t('account.couriers.labels.extraKm_variabil') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.formExtraKm')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formExtraKm"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" v-if="formUseautoshipping && !formCalculareDupaContract">
                                <el-col>
                                    <div class="box-title">
                                        {{ $t('account.couriers.courier-settings-costuri-asigurare') }}
                                    </div>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.calculateAsig') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formCalculateAsig')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select
                                                v-model="formCalculateAsig"
                                                placeholder="Select"
                                                class="fullWidth"
                                            >
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12" v-if="formCalculateAsig">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.asigSumaStart') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formAsigSumaStart')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formAsigSumaStart"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12" v-if="formCalculateAsig">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.asigProcentAdd') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formAsigProcentAdd')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formAsigProcentAdd"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" v-if="formUseautoshipping && courier.open_package">
                                <el-col>
                                    <div class="box-title">
                                        {{ $t('account.couriers.courier-settings-costuri-openpackage') }}
                                    </div>

                                    <el-col :xs="12" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.openpackage') }}
                                                <el-tooltip
                                                    :content="$t('account.couriers.tooltips-curieri-modal.openpackage')"
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="formOpenCollet" placeholder="Select" class="fullWidth">
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12" v-if="formOpenCollet && !formCalculareDupaContract">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.openpackagePrice') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.openpackagePrice')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="fromOpenColletPrice"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" v-if="formUseautoshipping">
                                <el-col>
                                    <div class="box-title">
                                        {{ $t('account.couriers.courier-settings-costuri-suplimentare') }}
                                    </div>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.allowCard') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formAllowCard')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="formAllowCard" placeholder="Select" class="fullWidth">
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.allowCash') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formAllowCash')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="formAllowCash" placeholder="Select" class="fullWidth">
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col
                                        :xs="24"
                                        :md="12"
                                        v-if="courier.saturday_delivery && !formCalculareDupaContract"
                                    >
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.saturdayAdd') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formSaturdayAdd')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formSaturdayAdd"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12" v-if="formAllowCash && !formCalculareDupaContract">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.rambursAdd') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formRambursAdd')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formRambursAdd"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.addCIndex') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formAllowCIndex')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="formAllowCIndex" placeholder="Select" class="fullWidth">
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" v-if="courier.hasLockers">
                                <el-col>
                                    <div class="box-title">{{ $t('account.couriers.courier-settings-locker') }}</div>

                                    <el-col :xs="24" :md="12">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.enableLockers') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formEnableLockers')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-select
                                                v-model="formEnableLockers"
                                                placeholder="Select"
                                                class="fullWidth"
                                            >
                                                <el-option :label="$t('general.no')" :value="0"></el-option>
                                                <el-option :label="$t('general.yes')" :value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :xs="24" :md="12" v-if="formEnableLockers && !formCalculareDupaContract">
                                        <el-form-item>
                                            <label class="el-form-item__label">
                                                {{ $t('account.couriers.labels.bazalocker') }}
                                                <el-tooltip
                                                    :content="
                                                        $t('account.couriers.tooltips-curieri-modal.formBazalocker')
                                                    "
                                                    placement="top"
                                                >
                                                    <el-button size="mini" class="buton_tooltip" round plain
                                                        >?</el-button
                                                    >
                                                </el-tooltip>
                                            </label>
                                            <el-input-number
                                                v-model="formBazalocker"
                                                :controls="false"
                                                class="fullWidth"
                                            ></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-form>
            </template>

            <span v-if="formId == 0" slot="footer" class="dialog-footer">
                <el-button v-if="activeStep > 0" @click="prevStep">{{ $t('account.couriers.prev-step') }}</el-button>
                <el-button type="primary" @click="nextStep">{{ $t('account.couriers.next-step') }}</el-button>
            </span>

            <span v-else slot="footer" class="dialog-footer">
                <el-button @click="nextStep" type="primary">{{ $t('account.couriers.button-save') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';

import md5 from 'md5';
import { getAccountData, getAccountShippingPolicy, setAccountShippingPolicy } from '@/api/cont';
import { debugPromise } from '@/utils';

export default {
    data() {
        return {
            formRules: {
                step_1: {
                    courier_id: [
                        {
                            required: true,
                            message: this.$t('account.couriers.errors.courier_id'),
                            trigger: ['change', 'blur'],
                        },
                    ],
                },
                step_2: {
                    service: [
                        {
                            required: true,
                            message: this.$t('account.couriers.errors.service'),
                            trigger: ['change', 'blur'],
                        },
                    ],
                    awb_format: [
                        {
                            required: true,
                            message: this.$t('account.couriers.errors.awb_format'),
                            trigger: ['change', 'blur'],
                        },
                    ],
                    name: [
                        {
                            required: true,
                            message: this.$t('account.couriers.errors.name'),
                            trigger: ['change', 'blur'],
                        },
                    ],
                },
            },
            courierMin1CC: true,
            shippingPolicy: 0,
            useautoshipping: 0,
            oauthAuthorizationWindow: null,
        };
    },
    components: {
        Box: () => import('vue-info-box-element'),
    },
    computed: {
        dialogInsert: sync('account/couriers@dialogInsert'),
        dialogEdit: sync('account/couriers@dialogEdit'),
        activeStep: sync('account/couriers@activeStep'),
        couriers_list: get('account/couriers@couriers_list'),
        couriersSettings: get('account/couriers@couriers_settings'),
        ...sync('account/couriers@form', {
            formId: 'id',
            formCourierId: 'courier_id',
            formName: 'name',
            formClientId: 'client_id',
            formUsername: 'username',
            formPassword: 'password',
            formAwbFormat: 'awb_format',
            formService: 'service',
            formEpod: 'epod',

            // GITLAB: marketplace/marketplace#61
            formPretBaza: 'baza',
            formKgIncluse: 'kgincluse',
            formCalculateKm: 'calculateKm',
            formExtraKm: 'extraKm',
            formCalculateKg: 'calculateKg',
            formExtraKg: 'extraKg',
            formCalculateAsig: 'calculateAsig',
            formAsigSumaStart: 'asigSumaStart',
            formAsigProcentAdd: 'asigProcentAdd',
            formRambursAdd: 'rambursAdd',
            formSaturdayAdd: 'saturdayAdd',
            formOpenCollet: 'openCollet',
            fromOpenColletPrice: 'openColletPrice',
            formLimita: 'limita',
            formLimitaBuc: 'limitabuc',
            formCalculateTransport: 'calculateTransport',
            formMinimumPrice: 'minimumPrice',
            formMinimumPriceBuc: 'minimumPricebuc',
            formAddTva: 'add_tva',
            formAllowCard: 'allowCard',
            formAllowCIndex: 'addCindex',
            formAllowCash: 'allowCash',
            formCalculareDupaContract: 'calculateByContract',
            formEnableLockers: 'enableLockers',
            formMinimumLockerPrice: 'minimumlockerPrice',
            formBazalocker: 'bazalocker',
            formUseautoshipping: 'useautoshipping',
        }),
        couriers: get('account/couriers@list'),
        form: get('account/couriers@form'),
        affName: get('user/info@name'),
        courier() {
            return this.couriers_list[this.formCourierId];
        },
    },
    methods: {
        handleSelectionChange(val) {
            setAccountShippingPolicy({
                shippingPolicy: val,
            });
            this.shippingPolicy = val;
        },
        getShippingPolicy() {
            getAccountShippingPolicy()
                .then((res) => {
                    if (typeof res?.message?.['shippingpickpolicy'] != 'undefined') {
                        this.shippingPolicy = res.message.shippingpickpolicy;
                    }
                    if (typeof res?.message?.['useautoshipping'] != 'undefined') {
                        this.useautoshipping = res.message.useautoshipping;
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getAccountShippingPolicy',
                        },
                    });
                    debugPromise('getAccountShippingPolicy', e);
                });
        },
        getAccountCouriers: call('account/GetAccountCouriers'),
        getCouriers: call('account/GetCouriers'),
        addAccountCourier: call('account/AddAccountCourier'),
        checkMin1CourierWithCardCash: call('account/checkMin1CourierWithCardCash'),
        getCourierAccount: call('account/GetCourierAccount'),
        setCourierAccountFavorite: call('account/SetCourierAccountFavorite'),
        updateCourierPickupPoints: call('account/UpdateCourierPickupPoints'),
        deleteCourierAccount: call('account/DeleteCourierAccount'),
        getCourierSettings: call('account/GetCourierSettings'),
        nextStep() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.activeStep == 1) {
                        this.addAccountCourier()
                            .then((res) => {
                                if (res.message.success) {
                                    this.getAccountCouriers().catch((e) => {
                                        this.$reportToSentry(e, {
                                            extra: {
                                                fn: 'getAccountCouriers',
                                            },
                                        });
                                    });

                                    this.checkCouriersMin1CC();

                                    if (this.formId > 0) {
                                        this.sbMsg({
                                            type: 'success',
                                            message: this.$t('account.couriers.updated'),
                                        });
                                    } else {
                                        this.sbMsg({
                                            type: 'success',
                                            message: this.$t('account.couriers.added'),
                                        });
                                    }

                                    this.dialogInsert = false;
                                    this.resetForm();
                                } else {
                                    if (res.message.error) {
                                        this.sbMsg({
                                            type: 'error',
                                            message: this.$t('account.couriers.errors.' + res.message.error),
                                        });
                                    } else {
                                        this.sbMsg({
                                            type: 'error',
                                            message: this.$t('account.couriers.errors.courier_account'),
                                        });
                                    }
                                }
                            })
                            .catch((e) => {
                                this.$reportToSentry(e, {
                                    extra: {
                                        fn: 'addAccountCourier',
                                        params: this.form,
                                    },
                                });
                            });
                    } else {
                        this.activeStep++;
                    }
                } else {
                }
            });
        },
        prevStep() {
            this.activeStep--;
        },
        resetForm() {
            this.activeStep = 0;
            this.formId = 0;
            this.formCourierId = '';
            this.formClientId = '';
            this.formUsername = '';
            this.formPassword = '';
            this.formAwbFormat = 'A4';
            this.formService = '';
            this.formName = '';

            // GITLAB: marketplace/marketplace#61
            this.formPretBaza = 0;
            this.formKgIncluse = 0;
            this.formCalculateKm = 0;
            this.formExtraKm = 0;
            this.formCalculateKg = 0;
            this.formExtraKg = 0;
            this.formCalculateAsig = 0;
            this.formAsigSumaStart = 0;
            this.formAsigProcentAdd = 0;
            this.formRambursAdd = 0;
            this.formSaturdayAdd = 0;
            this.formOpenCollet = 0;
            this.fromOpenColletPrice = 0;
            this.formLimita = 0;
            this.formLimitaBuc = 0;
            this.formCalculateTransport = 0;
            this.formMinimumPrice = 0;
            this.formMinimumPriceBuc = 0;
            this.formAddTva = 0;
            this.formAllowCard = 1;
            this.formAllowCIndex = 1;
            this.formAllowCash = 1;
            this.formCalculareDupaContract = 0;
            this.formEnableLockers = 0;
            this.formMinimumLockerPrice = 0;
            this.formBazalocker = 0;

            this.formUseautoshipping = 0;
        },
        checkCouriersMin1CC() {
            this.getAccountCouriers()
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getAccountCouriers',
                        },
                    });
                })
                .then((data) => {
                    let card = false;
                    let cash = false;

                    data.message.forEach((curier) => {
                        if (curier.allowCard) card = true;
                        if (curier.allowCash) cash = true;
                    });

                    this.courierMin1CC = card && cash;
                });
        },
        async setCourier(courier_id) {
            this.formCourierId = courier_id;
            this.formName = this.courier.name + ' - ' + this.affName;
            return this.getCourierSettings(courier_id);
        },
        editAccount(courier_account) {
            this.getCourierAccount({ id: courier_account })
                .then((res) => {
                    this.activeStep = 1;
                    this.formId = res.message.id;
                    this.formCourierId = res.message.courier_id;
                    this.formUsername = res.message.username;
                    this.formPassword = res.message.password;
                    this.formClientId = res.message.client_id;
                    this.formAwbFormat = res.message.awb_format;
                    this.formService = res.message.service;

                    // GITLAB: marketplace/marketplace#61
                    this.formPretBaza = res.message.baza;
                    this.formKgIncluse = res.message.kgincluse;
                    this.formCalculateKm = res.message.calculateKm;
                    this.formExtraKm = res.message.extraKm;
                    this.formCalculateKg = res.message.calculateKg;
                    this.formExtraKg = res.message.extraKg;
                    this.formCalculateAsig = res.message.calculateAsig;
                    this.formAsigSumaStart = res.message.asigSumaStart;
                    this.formAsigProcentAdd = res.message.asigProcentAdd;
                    this.formRambursAdd = res.message.rambursAdd;
                    this.formSaturdayAdd = res.message.saturdayAdd;
                    this.formOpenCollet = res.message.openCollet;
                    this.fromOpenColletPrice = res.message.openColletPrice;
                    this.formLimita = res.message.limita;
                    this.formLimitaBuc = res.message.limitabuc;
                    this.formCalculateTransport = res.message.calculateTransport;
                    this.formMinimumPrice = res.message.minimumPrice;
                    this.formMinimumPriceBuc = res.message.minimumPricebuc;
                    this.formAddTva = res.message.add_tva;
                    this.formAllowCIndex = res.message.addCindex;
                    this.formAllowCard = res.message.allowCard;
                    this.formAllowCash = res.message.allowCash;
                    this.formCalculareDupaContract = res.message.calculateByContract;
                    this.formEnableLockers = res.message.enableLockers;
                    this.formMinimumLockerPrice = res.message.minimumlockerPrice;
                    this.formBazalocker = res.message.bazalocker;
                    this.formUseautoshipping = res.message.useautoshipping;

                    if (res.message.epod) {
                        this.formEpod = true;
                    }
                    this.formName = res.message.name;

                    this.dialogInsert = true;
                })
                .then(() => {
                    return this.getCourierSettings(this.formCourierId);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCourierAccount',
                            params: {
                                id: courier_account,
                            },
                        },
                    });
                });
        },
        setFavorite(courier_account, type) {
            this.setCourierAccountFavorite({
                id: courier_account,
                type: type,
            })
                .then((res) => {
                    if (res.message.success) {
                        this.getAccountCouriers().catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'getAccountCouriers',
                                },
                            });
                        });
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'setFavorite',
                            params: {
                                id: courier_account,
                                type: type,
                            },
                        },
                    });
                });
        },
        courierHasLockers(row) {
            return typeof row.enableLockers != 'undefined' && row.enableLockers === 1;
        },
        updatePickupPoints(id) {
            this.updateCourierPickupPoints({
                id: id,
            })
                .then((res) => {
                    if (res.message.success) {
                        this.sbMsg({
                            type: 'success',
                            message: this.$t('account.couriers.pickup-points-updated'),
                        });
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'updateCourierPickupPoints',
                            params: {
                                id: id,
                            },
                        },
                    });
                });
        },
        deleteAccount(id) {
            this.$confirm(this.$t('account.couriers.confirm-delete'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning',
            })
                .then(() => {
                    this.deleteCourierAccount({
                        id,
                    })
                        .then((res) => {
                            if (res.message.success) {
                                this.sbMsg({
                                    type: 'success',
                                    message: this.$t('account.couriers.deleted'),
                                });
                                this.getAccountCouriers().catch((e) => {
                                    this.$reportToSentry(e, {
                                        extra: {
                                            fn: 'getAccountCouriers',
                                        },
                                    });
                                });
                            }
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'deleteAccount',
                                    params: {
                                        id: id,
                                    },
                                },
                            });
                        });
                })
                .catch(() => {});
        },
        openEcoletAuthorization() {
            const settings = this.couriersSettings[this.formCourierId];
            const state = md5(
                Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            );
            const url = `${settings.oauthAuthorizationLink}?client_id=${settings.clientId}&response_type=code&state=${state}`;
            this.oauthAuthorizationWindow = window.open(url, 'Ecolet Authorization Window', 'width=600,height=400');
        },
        handleAuthorizationResponse(event) {
            if (event.origin !== window.location.origin) {
                // Ignore messages from unknown origins
                console.log('Ignoring message from unknown origin:', event.origin);
                return;
            }

            const { code } = event.data;
            if (code) {
                this.sbMsg({
                    type: 'success',
                    message: this.$t('account.couriers.ecolet-authorization-successful'),
                });
                console.log('Authorization code received:', code);
                this.formClientId = code;
                this.formUsername = '';
                this.formPassword = '';
            }
        },
    },
    mounted() {
        this.getAccountCouriers().catch((e) => {
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getAccountCouriers',
                },
            });
        });

        this.getCouriers().catch((e) => {
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getCouriers',
                },
            });
        });

        this.getShippingPolicy();
        window.addEventListener('message', this.handleAuthorizationResponse);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleAuthorizationResponse);
    },
};
</script>

<style lang="scss" scoped>
.buton_tooltip {
    background: #8b8b8b;
    color: white;
    padding: 2px 5px;
    border-radius: 10px;
    vertical-align: bottom;
}

.courier-steps {
    margin-bottom: 20px;
}

.courier {
    text-align: center;
    height: 120px;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    border: 1px #f1f1f1 solid;
    border-radius: 3px;
    padding: 10px;

    .courier-image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
    }
}

.courier.active {
    border-color: #e95a1b;
}

.courier:hover {
    cursor: pointer;
}

.box-title {
    font-size: 20px;
    color: #f17146;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px #ffbaa3 solid;
    padding-bottom: 7px;
}
</style>

<style>
.fullWidth.el-input-number.is-without-controls .el-input__inner {
    text-align: left !important;
}

.ecolet-courier-container {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
</style>
